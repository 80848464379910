<template>
  <div :class="classInput">
    <div class="form-group" :class="{'is-invalid': isInvalid, 'input-group-disabled': isDisabled}">
      <label class="label" :class="{'required': isRequired}">{{ label }}</label>
      <textarea
        :value="value"
        rows="3"
        class="form-control"
        :class="{'is-invalid': isInvalid}"
        @blur="() => validate()"
        @input="inputHandle($event.target.value)"
        :ref="'refinput'+id"
      ></textarea>
      <div
        v-if="isInvalid"
        class="invalid-feedback"
      >
      <span v-if="isInvalid">Campo obrigatório.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    typeInput: {
      type: String
    },
    value: {
      type: String
    },
    classInput: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isInvalid: false,
      hasTouched: false
    }
  },
  methods: {
    inputHandle (value) {
      this.hasTouched = true
      this.$emit('input', value)
    },
    validate () {
      this.isInvalid = (this.hasTouched && this.value === null) || (this.isRequired && !this.value)
    },
    isValid () {
      this.validate()
      return !this.isInvalid
    },
    clean () {
      this.isInvalid = false
      this.hasTouched = false
    }
  }
}
</script>
